.staff-contact-chat {
  .time-message {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // padding: 10px 0px;
    font: normal normal normal 14px/30px Hiragino Sans;
    // margin: 10px 0px;
  }

  .me-content-cover {
    // width: 500px;
    margin-left: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 10px;
  }

  .message-date {
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    font: normal normal normal 14px/30px Hiragino Sans;
  }

  .message-list {
    height: 550px;
    overflow-x: hidden;
  }
}

.contact-list {
  .dot {
    height: 10px;
    width: 10px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }

  .white-dot {
    height: 10px;
    width: 10px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
  }

  .contact {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .underline-news {
    border-bottom: 2px solid #c3c3c3;
    padding-top: 15px;
    margin-bottom: 25px;
  }

  .new-contact {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    // align-items: center;
  }

  .ant-form-item .ant-form-item-label > label {
    min-width: 90px !important;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .new-contact-button {
    width: 88px;
    height: 35px;
    background-color: #e07b2e;
    color: #fff;
    font-size: 14px;
    font-family: Arial;
  }
}
.guest-content {
  max-width: 500px;
  background-color: #f0f0f0;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  padding: 10px;
  float: left;
  font: normal normal normal 14px/30px Hiragino Sans;
}
.me-content {
  max-width: 500px;
  float: right;
  background-color: #cadefc;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  padding: 10px;
  font: normal normal normal 14px/30px Hiragino Sans;
}
