@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #e07b2e;
  --secondary-color: #f5f4f2;
  --header-color: #888888;
  --placeholder-color: #c3c3c3;
  --primary-text-dark: #000000;
  --secondary-text-dark: #000000;
  --primary-text-light: #ffffff;
  --secondary-text-light: #ffffff;
  --primary-font-family: Arial;
  --secondary-font-family: "Arial";
  --shadow-right-default: 2px 4px 5.3px 0px rgba(0, 0, 0, 0.15);
  --white-color: #ffffff;
  --iris-100: #5d5fef;
  --border-color-default: rgba(0, 0, 0, 0.15);
  --text-color: #a3a3a3;
  --text-light-color: #999;
  --blue-primary: #1890ff;
  --light-blue-primary: #cae9ff;
  --text-light-color: #0b3f65;
}

body {
  color: var(--primary-text-dark);
  font-family: var(--primary-font-family);
  overflow-y: scroll;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1;
  /* Firefox */
}

.page-header-bg {
  & > * {
    position: relative;
    z-index: 1;
  }

  @apply after:content-[''] after:absolute after:top-0 after:bg-white after:left-0 after:right-0 after:z-0;
}

.ant-picker-suffix {
  color: rgba(0, 0, 0, 1) !important;
}

.font-hiragino {
  font-family: "Hiragino Sans";
}

option {
  font-size: 14px !important;
}

input:focus::placeholder {
  color: transparent !important;
}

.ant-picker-dropdown {
  z-index: 99999;
}

.tabs-custom {
  font-size: 14px;
  line-height: 24px;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #28a138;
  height: 2px !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000 !important;
}

.ant-tabs-tab {
  margin: 0 !important;
}

.ant-tabs-tab-btn {
  font-weight: 600;
  padding: 0 8px;
  &:after {
    content: "";
    width: 2px;
    height: 15px;
    top: 15px;
    right: -7px;
    background: #e0e0e0;
    position: absolute;
  }
}

.ant-tabs-nav-list {
  column-gap: 14px;
}

.page-title {
  font-size: 24px;
  margin: 20px 0;
}

.page-container {
  background: white;
  padding: 20px;
}

.ant-input,
.ant-picker,
.ant-input-number {
  border-color: black;
}

.ant-select:not(.ant-select-status-error):not(.ant-select-disabled)
  .ant-select-selector {
  border-color: black;
}

.ant-input,
.ant-input-password,
.ant-select-selector,
.ant-picker,
.ant-input-number {
  border-radius: 0px;
}

.ant-select-single .ant-select-selector {
  border-radius: 0;
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

.ant-input-number {
  width: 100%;
}

th.ant-table-cell {
  white-space: nowrap;
}

.ant-input-group .ant-input-group-addon {
  background: transparent;
  border: none;
}

.ant-table-wrapper .ant-table-thead {
  > tr > th,
  > tr > td {
    background: var(--secondary-color);
  }
}

.with-color-row.ant-table-wrapper {
  .ant-table-thead > tr > td,
  .ant-table-tbody .ant-table-row:nth-child(2n + 1) > td {
    background: var(--secondary-color);
  }
}

.with-color-row-even.ant-table-wrapper {
  .ant-table-thead > tr > td,
  .ant-table-tbody .ant-table-row:nth-child(2n) > td {
    background: var(--secondary-color);
  }
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}
