.nursing-care-service-detail .save-button {
    height: 35px;
    padding: 0px 54px;
    font-size: 14px;
    line-height: 24px;
    background-color: #e07b2e;
    color: white;
}
.nursing-care-service-list .ant-table-column-sort{
    background-color: initial !important;
}

