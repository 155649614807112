.shift-registration-detail .save-button {
    height: 35px;
    padding: 0px 54px;
    font-size: 14px;
    line-height: 24px;
    background-color: #e07b2e;
    color: white;
}

.shift-registration-detail .white .ant-select-selector {
    background-color: #fff !important;
}
.shift-registration-detail .red .ant-select-selector {
    background-color: #eb4034 !important;
}
.shift-registration-detail .yellow .ant-select-selector {
    background-color: #f7f71b !important;
}
.shift-registration-detail .green .ant-select-selector {
    background-color: #1bf738 !important;
}
.shift-registration-detail .blue .ant-select-selector {
    background-color: #1b7af7 !important;
}
.shift-registration-detail .purple .ant-select-selector {
    background-color: #941bf7 !important;
}
.shift-registration-detail .pink .ant-select-selector {
    background-color: #f71bb1 !important;
}
// .shift-registration-detail .black .ant-select-selector {
//     background-color: #000;
// }

.shift-registration-list .ant-table-column-sort {
    background-color: initial !important;
}
.column-bg{
    background-color: red;
}
