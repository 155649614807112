.customPaginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  .pagination-bar-wrapper {
    display: flex;
    column-gap: 5px;
    .paginate-btn {
      border: 1px solid #000000;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      &.active {
        background-color: #000000;
        color: #fff;
      }
      &.ellipsis {
        pointer-events: none;
      }
      &.disabled {
        opacity: 0.2;
        pointer-events: none;
      }
      &:hover:not(.active) {
        background-color: rgb(238, 238, 238);
      }
    }
  }
}