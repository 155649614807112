.facility-registration-detail .save-button {
    height: 35px;
    padding: 0px 54px;
    font-size: 14px;
    line-height: 24px;
    background-color: #e07b2e;
    color: white;
}

.facility-registration-detail .save-button {
    height: 35px;
    padding: 0px 54px;
    font-size: 14px;
    line-height: 24px;
    background-color: #e07b2e;
    color: white;
}

.stock-change-item {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.facility-list .ant-table-column-sort {
    background-color: initial !important;
}
