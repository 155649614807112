.tab-panel {
  .tab-week-panel {
    padding: 42px 0;

    .table-container {
      td {
        padding: 0;

        > :nth-child(n) {
          margin: 4px;
        }
      }
    }
  }
}
.service-name {
  white-space: nowrap; 
  width: 100px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

