.shift {
    display: flex;
    gap: 1.5rem;

    > span {
        display: inline-flex;
        align-items: center;
    }
}

.designedCountBtn {
  line-height: 0;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid #000000;
}

.datePickerCustom {
  width: 100%;
  padding: 14px 8px;
}

.select-custom {
  .ant-select-selector {
    height: 49px !important;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }
  .ant-select-arrow {
    .ant-select-suffix {
      display: none;
    }
    &::after {
      content: "";
      height: 8px;
      width: 8px;
      border-bottom: 2px solid #000000;
      border-right: 2px solid #000000;
      rotate: 45deg;
      position: absolute;
      right: 10%;
      transform: translateX(-50%);
    }
  }
  &.ant-select-disabled {
    .ant-select-arrow {
      &::after {
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
      }
    }
  }
}

.rangePicker {
  border: none !important;
  box-shadow: none;
  padding: 0 !important;

  .ant-picker-range-separator {
    .ant-picker-separator {
      display: none;
    }
    &::after {
      content: "~";
    }
  }

  .ant-picker-suffix {
    display: none;
  }

  .ant-picker-input {
    border: 1px solid #000000;
    border-radius: 4px;
    max-width: 64px;

    input {
      height: 40px;
      font-size: 11px;
      padding: 14px 8px;
    }
  }

  .ant-picker-active-bar {
    display: none;
  }

  .ant-picker-input::after {
    content: "";
    height: 8px;
    width: 8px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    rotate: 45deg;
    position: absolute;
    right: 10%;
    transform: translateX(-50%);
  }

  .ant-picker-clear {
    display: none !important;
  }
}
