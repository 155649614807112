.tab-panel {
  .tab-month-panel {
    padding: 0;

    .calendar {
      .ant-picker-calendar-date {
        .ant-picker-calendar-date-value {
          position: absolute;
          right: 8px;
        }
        .ant-typography {
          font-weight: normal;
        }
      }
    }
  }
}
