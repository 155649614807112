.login-admin {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #f5f4f2;
    width: 40%;
    margin: auto;
    padding: 5% 0px;
}

.login-admin .input {
    width: 250px;
    height: 40px;
    border: 1px solid #abaaaa;
    margin: 3px 0px
}

.login-admin .button {
    background-color: #f5f4f2 !important;
    border-color: black;
    border-radius: 0px;
    margin: 10%;
}
.login-admin .button-hs {
    background-color: #ffffff !important;
    border-color: #707070;
}
