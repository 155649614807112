.ant-input {
  border-color: #000000;
  //height: 40px;
}
.ant-form-item .ant-form-item-label > label::after {
  display: none !important;
}
.ant-form-item .ant-form-item-label > label {
  min-width: 159px;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  color: #e07b2e;
  content: "※";
}

.timeRangePicker {
  border: none !important;
  box-shadow: none;
  column-gap: 30px !important;
  padding: 0 !important;
  .ant-picker-range-separator {
    display: none;
  }
  .ant-picker-suffix {
    display: none;
  }
  .ant-picker-input {
    border: 1px solid #000000;
  }
  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-input {
    border-radius: 4px;
    max-width: 64px;
    input {
      padding: 14px 8px;
    }
  }
  .ant-picker-input::after {
    content: ">";
    rotate: 90deg;
    color: #000000;
    position: absolute;
    right: 10%;
    top: 48%;
    transform: translateX(-50%);
    font-size: 20px;
    font-weight: 400;
  }
}
