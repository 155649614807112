/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 5px;
  height: 9px;
  background: #fff;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 2px;
  background: #888888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #707070;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5f4f2;
}