.custom-more {
  .fc-popover {
    opacity: 0;
  }
}

.custom-title {
  .fc-daygrid-event-harness {
    cursor: pointer !important;
  }
}

.full-calendar {
  & .fc-header-toolbar {
    & .fc-toolbar-chunk:nth-child(2) div {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;

      & .fc-toolbar-title {
        color: #323232;
        width: 120px;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & button {
        background-color: transparent;
        color: black;
        border: none;
        outline: none;

        &:focus {
          box-shadow: none;
        }
      }

      & button:nth-child(1) span,
      button:nth-child(3) span {
        font-size: 20pxØ;
        cursor: pointer;
      }
    }
  }

  .fc-h-event {
    & .fc-event-title {
      font-size: 14px;
    }
  }

  .fc .fc-button .fc-icon {
    font-size: 1em;
    color: #686868;
  }

  .fc-dayGridMonth-view {
    & .fc-col-header-cell {
      .fc-col-header-cell-cushion {
        color: #6e6b7b;
        font-weight: medium;
        font-size: 16px;
      }
    }

    .fc-daygrid-day-top {
      flex-direction: row;

      & > a {
        color: black;
        font-size: 16px;
      }
    }

    .fc-daygrid-day-events {
      & .fc-event {
        padding: 3px 6px;
      }

      & .success {
        background-color: #28c76f1f;
        border: none;

        & .fc-event-title {
          color: #28c76f;
        }
      }

      & .error {
        background-color: #f747471f;
        border: none;

        & .fc-event-title {
          color: #f64747;
        }
      }

      & .info {
        background-color: #7367f01f;
        border: none;

        & .fc-event-title {
          color: #7367f0;
        }
      }

      & .default {
        background-color: rgba(133, 133, 133, 0.12);
        border: none;

        & .fc-event-title {
          color: #191919;
        }
      }
    }

    & .fc-daygrid-more-link {
      font-size: 12px;
      color: rgba(#000000, 0.6);
    }
  }
}

@media (max-width: 768px) {
  .ant-picker-calendar-header {
    display: none !important;
  }

  .custom-calendar {
    .ant-picker-calendar-date-today {
      background: transparent !important;
      &::before {
        border: 1px solid transparent !important;
        background-color: transparent;
      }
    }

    .ant-picker-cell .cell-date {
      opacity: 0.3;
    }

    .ant-picker-cell.ant-picker-cell-in-view .cell-date,
    .ant-picker-cell.ant-picker-cell-in-view .cell-date {
      opacity: 1;
    }
  }

  .full-calendar {
    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background: transparent !important;
      }

      .cell-date {
        border: 1px solid #1677ff;
        border-radius: 8px !important;
      }
    }
  }

  .ant-picker-calendar-date-today {
    &::before {
      display: none;
    }
  }
}

.month {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #222b45;
}

.year {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #8f9bb3;
}

.ant-picker-calendar-date-value {
  display: none !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.875rem !important;
}
