.customInp {
  border-radius: 0px;

  .ant-input::placeholder {
    color: #000000 !important;
    font-size: 12px;
    line-height: 20px;
  }
}

.page-title-detail {
  font-size: 24px;
  margin: 20px 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-board {
  .comment {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .underline-news {
    border-bottom: 2px solid #c3c3c3;
    padding-top: 15px;
    margin-bottom: 25px;
  }

  .ant-tree-switcher {
    display: none !important;
  }

  .ant-tree-node-content-wrapper:hover {
    background-color: white;
  }

  .ant-tree-node-content-wrapper {
    background-color: white !important;

    .anticon.anticon-caret-down {
      position: relative;
      width: 100%;
      height: 100%;
      svg {
        display: none;
      }
      &:before {
        content: "▼";
        width: 100%;
        display: inline-block;
      }
    }
  }
}

.communication {
  .new-contact {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
  }

  .paging-head {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .contact {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .underline-news {
    border-bottom: 2px solid #c3c3c3;
    padding-top: 15px;
    margin-bottom: 25px;
  }
}

.guest-message {
  float: left;
}

.me-message {
  float: right;
}

.message-date {
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.bottom-input {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.keep-button {
  background-color: #e07b2e !important;
}

.post-detail .save-button {
  height: 35px;
  padding: 0px 54px;
  font-size: 14px;
  line-height: 24px;
  background-color: #e07b2e;
  color: white;
}

.patient-list {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.patient-item {
  // background-color: green;
  padding: 2px;
  border-radius: 5px;
  color: white;
}

.important {
  background-color: #e07b2e;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #000000;
}

.custom-checkbox {
  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 0px;
      border: 1px solid #000000;
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #000000;
      }
    }
  }
}
